@import './../../../../scss/theme-bootstrap';

// Sign-in Form. This applies to both the gnav dropdown and the standalone
// perlgem page.
.signin-block {
  padding: 20px;
  width: 100%;
  text-align: left;
  a {
    text-decoration: underline;
  }
  .gnav-util__content--account & {
    padding: 0;
  }
  .elc-user-state-logged-in & {
    padding: 0;
    @include breakpoint($landscape-up) {
      padding: 20px;
    }
  }
  &__forms {
    .elc-user-state-logged-in & {
      display: none;
    }
  }
  &--signin {
    .signin-block__form--signin {
      display: block;
    }
  }
  &__form--signin {
    //display: none;
  }
  &__main,
  &__footer {
  }
  &__title {
    .gnav-util__content--account & {
      margin-bottom: 20px;
      .network-gnav-account & {
        margin-bottom: 10px;
      }
    }
  }
  &__subtitle {
    font-size: 1.5em;
    line-height: 1;
    text-align: center;
  }
  &__field {
    display: block;
    width: 100%;
    margin: 0 auto 10px;
  }
  &__forgot-password {
    font-family: $font--avenir-medium;
    font-size: 14px;
    text-decoration: underline;
  }
  &__submit {
    width: 100%;
    margin-top: 6px;
  }
  &__register {
    margin-bottom: 6px;
    .gnav-util__content--account & {
      margin-bottom: 8px;
    }
    &__link {
      font-family: $font--avenir-medium;
      font-size: 14px;
      text-decoration: underline;
    }
  }
  &__authed {
    display: none;
    &__title {
    }
    &__content {
    }
    &__list {
      line-height: 30px;
    }
    .elc-user-state-logged-in & {
      display: block;
    }
  }
  .error_messages {
    li {
      color: $color-red;
    }
  }
}

.network-gnav-account {
  body.elc-user-state-logged-in & {
    .signin-block__authed__nav {
      display: none;
    }
    .gnav-util__content--account {
      right: -15px;
    }
    .signin-block {
      padding: 0px;
    }
    .signin-block .network-signed-in-content {
      .section-header,
      a {
        display: inline-block;
        font-size: 16px;
        font-weight: normal;
        font-family: $base-font-family;
        line-height: 1em;
        white-space: nowrap;
        padding: 10px 0px 5px 0px;
        margin: 0px;
      }
      li a {
        display: inline-block;
        font-size: 13px;
        line-height: 1.7em;
        padding: 0px;
      }
    }
  }
}

.gnav-util__content--account {
  input[type='email'],
  input[type='password'] {
    height: 50px;
    line-height: 50px;
  }
  input[type='password'] {
    margin-bottom: 18px;
  }
  input[type='submit'] {
    font-size: 20px;
    padding: 10px 25px 10px 25px;
  }
}

// auth states visibility
.elc-user-state-anonymous {
  .hide_anon {
    display: none;
  }
}

.elc-user-state-logged-in {
  .hide_auth {
    display: none;
  }
}
